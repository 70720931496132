import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Box from 'components/box';
import Head from 'components/head';
import Title from 'components/title';
const ReactMarkdown = require('react-markdown')


const Privacy = ({ data }) => (
  <Layout>
    <Head pageTitle={data.privacyJson.title} />
    <Box>
      <Title as="h2" size="large" style={{ fontFamily: 'Montserrat' }}>
        {data.privacyJson.title}
      </Title>
      <div style={{ height: '5vh' }} />
      <div><ReactMarkdown source={data.privacyJson.content.childMarkdownRemark.rawMarkdownBody} /></div>
    </Box>
  </Layout>
);

Privacy.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Privacy;

export const query = graphql`
  query PrivacyQuery {
    privacyJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
    }
  }
`;
